//create a meal page component with details about the meal

import React, { useState, useEffect } from "react";
import "./simple.css";
import "./create-meals.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import demo from "../demo-picture.jpg";


const API_URL = process.env.REACT_APP_API_ENDPOINT;

function MealPage() {
  const [meal, setMeal] = useState({
    title: "",
    description: "",
    price: "",
    plz: "",
  });

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetch(`${API_URL}/meals/${id}`)
      .then((response) => response.json())
      .then((data) => setMeal(data));
  }, [id]);

  if (!meal) {
    return <h2>Loading...</h2>;
  }

  return (
    <div className="meal">
      <img
        src={demo}
        className="mealImage"
        style={{ height: "400px" }}
      ></img>
      <h1>{meal.title}</h1>
      <p>{meal.description}</p>
      <p>{meal.price}</p>
      <p>{meal.plz}</p>
    </div>
  );
}

export default MealPage;

import "./simple.css";
import React, { useEffect, useState } from "react";
import "./navbar.css";
import Logo from "../image.png";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const Navbar=(props) => {

    const [scrolled,setScrolled] = useState(false);
    const [navbarClasses, setNavbarClasses] = useState(['navbar']);
    const [error, setError] = useState(null);


  const handleScroll = () => {
    if (window.scrollY > 200) {
      setScrolled(true, setNavbarClasses(["navbar", "scrolled"]));
    } else {
      setScrolled(false, setNavbarClasses(["navbar"]));
    }
  };

  useEffect(() => {
    //listen for scroll event
    window.addEventListener("scroll", handleScroll);

    if(scrolled){
        setNavbarClasses(['navbar','scrolled'])
    } else {
      setNavbarClasses(["navbar"]);
    }

    if (props.user.email != localStorage.getItem('user')) {
        props.updateUser({
            email: localStorage.getItem('user')
        });
    }

    //cleanup
    return () => {
        window.removeEventListener('scroll',handleScroll)
    }


    
  },[scrolled, props.user.email])

    
    

  return (
    <header className={navbarClasses.join(" ")}>

        <div className="logo">
            <a href="/"><img src={Logo} /></a>
        </div>
        <nav className="navigation">
            <ul>
                
                <li><a href="/create">Create Meal</a></li>
                <li>
                    {localStorage.getItem("token") ? <a href="/messages">Chat</a> : ""}
                </li>
                <li>
                    {localStorage.getItem("user") && props.user.email ? 
                    <a href="/user">
                        {props.user.email.slice(0,props.user.email.indexOf("@"))}
                    </a> : 
                        <a href="/signup">Sign Up</a>
                    }
                </li>
                <li>
                    {localStorage.getItem("token") ? 
                    "" : 
                        <a href="/login">Login</a>
                    }
                </li>
            </ul>
        </nav>

    </header>
  );
};
export default Navbar;

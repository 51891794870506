//create a search component to search for meals titles using a search bar
//the component gets passed a filter function from the parent component
//the filter function is called when the user types in the search bar
//the filter function is called with the search term as an argument
//the filter function is called when the user clicks the search button

import React, { useState } from "react";
import "./simple.css";
import "./create-meals.css";
import { useNavigate } from "react-router-dom";
import "./search.css";

function Search(props) {
    const [searchTerm, setSearchTerm] = useState("");
    
    const navigate = useNavigate();
    
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("new search term: " + searchTerm);
        props.searchHandler(searchTerm);
    };
    
    return (
        <div>
            <form className="search" onSubmit={handleSubmit}>
                <input
                type="text"
                name="search"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
                />
                <button className="searchButton" type="submit">Search</button>
            </form>
        </div>
    );
    }

export default Search;

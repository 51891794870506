//create a signup component with username, email and password form that posts to the signup endpoint at the same url as in meals
import React, { useState, useEffect } from "react";
import "./simple.css";
import "./signup.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function SignUp() {
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [signUpError, setSignUpError] = useState(null);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setNewUser({
      ...newUser,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

  const signup = async () => {
      try {
        const response = await fetch(`${API_URL}/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          mode: "cors",
          body: JSON.stringify(newUser),
        });

        if (!response.ok) {
          const data = await response.json();
          console.log(data.error);
          setSignUpError(data.error);
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
          
        } else {
          const data = await response.json();
          console.log(data);
          navigate('/login');
        }
      } catch (error) {
        console.log(error);
      }
    };
    signup();

        
    }
    

  return (
    <div className="signup">
      <h1>Sign Up</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">Name</label>
        <input
          type="text"
          name="name"
          id="name"
          value={newUser.name}
          onChange={handleChange}
        />
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          value={newUser.email}
          onChange={handleChange}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          value={newUser.password}
          onChange={handleChange}
        />
        <br></br>
        <button type="submit">Sign Up</button>
        <Link to="/login" style={{ marginLeft: "1em" }}>
          Login Instead
        </Link>
      </form>
      <p style={{color:"red"}}>{signUpError}</p>
    </div>
  );
}

export default SignUp;

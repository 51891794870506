import React from "react";
import "./chat.css";

function ChatMessage({ message }) {
  const messageClass =
    message.user_from.toString() === localStorage.getItem("userId")
      ? "sent"
      : "received";

  const date = new Date(message.created_at);

  return (
    <div className="messageBox">
      <div className={`message ${messageClass}`}>
        <img src={"https://api.adorable.io/avatars/23/abott@adorable.png"} />
        <p>{message.body}</p>
      </div>
      <p className={`timestamp ${messageClass}`}>
        {date.getHours() + ":" + padMinutes(date.getMinutes())}
      </p>
    </div>
  );
}

function padMinutes(minutes) {
  const minStr = minutes.toString();
  if (minStr.length === 1) {
    return "0" + minStr;
  }
  return minStr;
}

export default ChatMessage;

//Path: src/components/Meals.js

import Navbar from "./Navbar";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./simple.css";
import "./meals.css";
import demo from "../demo-picture.jpg";
import Search from "./Search";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function Meals() {
  const [meals, setMeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loginState, setLoginState] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const getMeals = async () => {
      try {
        const response = await fetch(`${API_URL}/meals`);
        if (!response.ok) {
          console.log(response.json().error);
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setMeals(filterMeals(actualData));
        setError(null);
      } catch (err) {
        setError(err.message);
        setMeals(null);
      } finally {
        setLoading(false);
      }
    };

    getMeals();
  }, [searchTerm]);

  //a function that takes in a search term and returns a filtered array of meals
  const filterMeals = (meals) => {

    if (typeof(searchTerm) == String || searchTerm.length>1) {
      console.log("Search: " + searchTerm);
      const filteredMeals = meals.filter((meal) => {
        return meal.title.toLowerCase().includes(searchTerm.toLowerCase());
      });
      console.log("filtered: " + searchTerm + " ;", filteredMeals);
      return filteredMeals;
    } else {
      console.log("Search: no search term");
      return meals;
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  try {
    return (
      <div className="page">
        <header className="mealHeader">
          <h1>Meals</h1>
          <Search searchHandler={handleSearch}/>
          <h1>{loginState}</h1>
        </header>
        <div className="main">
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {meals.length > 0 && (
            <div className="mealGrid">
              {meals
                .slice(0)
                .reverse()
                .map((meal) => {
                  //check if all properties are present
                  if (meal.title && meal.description && meal.price) {
                    return (
                      <div className="meal">
                        <Link
                          to={`/meals/${meal.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div key={meal.id} className="mealBox">
                            <img src={demo} className="mealImage"></img>
                            <div className="mealInfo">
                              <h2
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {meal.title}
                              </h2>
                              <p>
                                {meal.description.length > 70
                                  ? meal.description.slice(0, 70) + "..."
                                  : meal.description}
                              </p>
                              <p>{meal.price ? meal.price + "€" : ""}</p>
                              <p>{meal.zip ? meal.zip : ""}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                })}
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    console.log(err);
  }
}

export default Meals;

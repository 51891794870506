// create a user component that shows the user's email and a logout button that deletes the token from local storage and redirects to the home page
// if the user is not logged in, redirect to the login page

import React, { useState, useEffect } from "react";
import "./simple.css";
import "./user.css";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function User(props) {

    const navigate = useNavigate();
    
    const logoutHandler = event => {
        event.preventDefault();
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        props.updateUser({
            email: ""
        });
        navigate('/');
    }

    //create an event handler that sends a get request to the /user endpoint and logs the response to the console
    const handleCharge = async event => {
        event.preventDefault();
        try {
            const response = await fetch(`${API_URL}/user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                mode: "cors",
            });
            if (!response.ok) {
                const data = await response.json();
                console.log(data.error);
                throw new Error(
                    `This is an HTTP error: The status is ${response.status}`
                );
            } else {
                const data = await response.json();
                console.log(data);
            }
        } catch (error) {
            console.log(error);
        }
    }


    
       
    if (!props.user) {
        navigate('/login')
    }
    
    return (
        <div className="user">
            <h1>{props.user.email}</h1>
            <button onClick={logoutHandler}>Logout</button>

            <button style={{backgroundColor: "red"}} onClick={handleCharge}>Ultracharge Now!</button>
        </div>
    );
}

export default User;

//create a chat feed component that shows the messages in the chat

import React from "react";
import ChatMessage from "./ChatMessage";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import "./chat.css";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function ChatRoom() {
  const dummy = useRef();
  const [chatrooms, setChatrooms] = useState([]);
  // store id of currently active chatroom
  const [currentChatroom, setCurrentChatroom] = useState(null);
  //fetch messages with a request from same url as in meals with endpoint /messages
  const [messages, setMessages] = useState([]);
  //new message state is object with text, user, timestamp and id
  const [messageBody, setMessageBody] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    async function getChatrooms() {
      const response = await fetch(`${API_URL}/chatrooms`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        mode: "cors",
      });
      if (!response.ok) {
        console.log(response);
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      } else {
        const chatrooms = await response.json();

        let newChatrooms = [];
        for (let room of chatrooms) {
          let newRoom = {
            id: room.id,
            last_message: room.last_message,
          };
          console.log("room id:" + room.id);
          // figure out who is sender and receiver in chatroom
          if (room.first_user.toString() === localStorage.getItem("userId")) {
            newRoom.user_id = room.second_user;
            newRoom.user = room.second_user_name;
          } else {
            newRoom.user_id = room.first_user;
            newRoom.user = room.first_user_name;
          }
          newChatrooms.push(newRoom);
        }
        setChatrooms(newChatrooms);
        // set first chatroom as active
        console.log("chatrooms: " + newChatrooms);
        setCurrentChatroom(newChatrooms[0].id);
      }
    }
    getChatrooms();
  }, []);

  useEffect(() => {
    // load messages when current chatroom changes
    const getMessages = async () => {
      if (!currentChatroom) return;

      const chatroom = chatrooms.find(
        (element) => element.id === currentChatroom
      );
      // user id of chat partner
      const userId = chatroom.user_id;
      try {
        const response = await fetch(`${API_URL}/messages/${userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          mode: "cors",
        });
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        } else {
          let actualData = await response.json();
          console.log(actualData);
          setMessages(actualData);
          setError(null);
        }
      } catch (error) {
        setError(error.message);
        setMessages("");
      } finally {
        setLoading(false);
      }
    };
    getMessages();
  }, [currentChatroom]);

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleChange = (event) => {
    setMessageBody(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const chatroom = chatrooms.find(
      (element) => element.id === currentChatroom
    );
    const userId = chatroom.user_id;

    const newMessage = {
      body: messageBody,
      user_to: userId,
    };

    const sendMessage = async () => {
      try {
        const response = await fetch(`${API_URL}/messages`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          mode: "cors",
          body: JSON.stringify(newMessage),
        });

        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        } else {
          const data = await response.json();
          console.log(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    sendMessage();
    setMessageBody("");

    setMessages([
      ...messages,
      {
        body: newMessage.body,
        user_from: localStorage.getItem("userId"),
        created_at: new Date(),
      },
    ]);
  };

  return (
    <div className="chatroom">
      <div className="conversations">
        <p>Conversations</p>
        {chatrooms &&
          chatrooms.map((chatroom) => {
            return (
              <div
                key={chatroom.id}
                className={`conversation ${
                  chatroom.id === currentChatroom && "active"
                }`}
                onClick={() => setCurrentChatroom(chatroom.id)}
              >
                {chatroom.user}
              </div>
            );
          })}
      </div>
      <div>
        <main>
          {messages.length > 0 &&
            messages.map((msg) => <ChatMessage key={msg.id} message={msg} />)}
          <span ref={dummy}></span>
        </main>
        <form className="chatform" onSubmit={handleSubmit}>
          <input
            name="text"
            value={messageBody}
            onChange={handleChange}
            placeholder="say something nice"
          />
          <button type="submit" className="sendButton" disabled={!messageBody}>
            🕊️
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChatRoom;

//create a react component for creating meals
//reference the meals component for the form
//use the same form for creating meals
//use the same form for updating meals

import React, { useState, useEffect } from "react";
import "./simple.css";
import "./create-meals.css";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function CreateMeals() {
    
  const [newMeal, setNewMeal] = useState({
    id: "",
    image: "",
    title: "",
    description: "",
    price: "",
    zip: "",
    user: {
      email: localStorage.getItem("user"),
    },
  });
  const [imgfile, setImgFile] = useState("");
  const [imgUpload, setImgUpload] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/signup');
        }
    }, []);

    
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setNewMeal({
      ...newMeal,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append("image", imgfile);
    data.append("title", newMeal.title);
    data.append("description", newMeal.description);
    data.append("price", newMeal.price);
    data.append("zip", newMeal.zip);

    try {
    const response = await fetch(`${API_URL}/meals`, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      credentials: "include",
      mode: "cors",
      body: data,
    });

    if (!response.ok) {
      const data = await response.json();
      console.log(data.error);
      setError(data.error);
      throw new Error(
        `This is an HTTP error: The status is ${response.status}`
      );
    } else {
      const data = await response.json();
      console.log(data);

      if(response.status === 200){
        //navigate("/meals");
      }
      
    }
    } catch (error) {
      console.log(error);
      setError(error);
    }

    
  };

  //create a function to handle the image change
  const handleImageChange = (event) => {
    setImgFile(event.target.files[0]);
  };

  return (
    <div className="createMeals">
      <h1>Create a Meal</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          name="title"
          id="title"
          value={newMeal.title}
          onChange={handleChange}
        />
        <label htmlFor="description">Description</label>
        <input
          type="text"
          name="description"
          id="description"
          value={newMeal.description}
          onChange={handleChange}
        />
        <label htmlFor="price">Price</label>
        <input
          type="number"
          name="price"
          id="price"
          value={newMeal.price}
          onChange={handleChange}
        />
        <label htmlFor="plz">Zip</label>
        <input
          type="number"
          name="zip"
          id="zip"
          value={newMeal.zip}
          onChange={handleChange}
          maxLength="5"
        />
        <label htmlFor="image">Image</label>
        <input type="file" name="image" id="image" onChange={handleImageChange} />
            
        <br></br>
        <button type="submit">Submit</button>
      </form>
      
    </div>
  );
}

export default CreateMeals;

// Path: src/components/Meals.js

//create a login component with a form for email and password that posts to the login endpoint at the same url as in meals

import React, { useState, useEffect } from "react";
import "./simple.css";
import "./login.css";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function Login(props) {

    const [user, setUser] = useState({
        email: "",
        password: "",
    })

    const [loginError, setLoginError] = useState(null);
    
    const navigate = useNavigate();
    
    const handleChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        
        setUser({
            ...user,
            [name]: value
        })
    }
        
    const handleSubmit = async event => {
        event.preventDefault();

      const handleLogin = async () => {
        try {
          const response = await fetch(`${API_URL}/login`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            mode: "cors",
            body: JSON.stringify(user),
          });

          if (!response.ok) {
            const data = await response.json();
            console.log(data.error);
            setLoginError(data.error);
            throw new Error(
                `This is an HTTP error: The status is ${response.status}`
            );
          } else {
              const data = await response.json();
              localStorage.setItem('token', "token");
              localStorage.setItem('user', data.user.email);
              //localStorage.setItem('userId', data.user.id);
              props.updateUser({
                email: user.email,
                id: user.id
              })
              console.log(data);
              navigate('/meals');
          }
      } catch (error) {
          console.log(error);
          setLoginError(error);
      }
      }
      handleLogin();
              
      
    }
    

  return (
    <div className="login">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          value={user.email}
          onChange={handleChange}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          value={user.password}
          onChange={handleChange}
        />
        <br></br>
        <button type="submit">Login</button>
      </form>
            <p style={{color:"red"}}>{loginError}</p>
    </div>
  );
}

export default Login;

import "./App.css";
import { useState, useEffect } from "react";
import "./components/simple.css";
import Navbar from "./components/Navbar";
import Meals from "./components/Meals";
//import react router and create a route for meals, create meal, and sign up
//show meals also on the home page
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateMeals from './components/CreateMeals';
import SignUp from './components/SignUp';
import MealPage from './components/MealPage';
import Login from './components/Login';
import User from './components/User';
import Chat from './components/chat/Chat';

const API_URL = process.env.REACT_APP_API_ENDPOINT;


function App() {

  const [user, setUser] = useState({
    email: "",
    id:"",
  });

  function updateUser(user) {
    setUser(user);
  }

  return (
    <div className="App">
      <Navbar updateUser={updateUser} user={user} />

      <Router>
        <Routes>
          <Route path="/" element={<Meals />} />
          <Route path="/meals" element={<Meals />} />
          <Route path="/create" element={<CreateMeals />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/meals/:id" element={<MealPage  />} />
          <Route path="/login" element={<Login updateUser={updateUser} user={user} />} />
          <Route path="/user" element={<User updateUser={updateUser} user={user} />} />
          <Route path="/messages" element={<Chat />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

//create a chat component that will render a chat feed and a chat input
//import the chat feed and chat input components
import React from "react";
import "./chat.css";
import { useNavigate } from "react-router-dom";
import ChatRoom from "./ChatRoom";

function Chat() {
  const navigate = useNavigate();

  if (!localStorage.getItem("token")) {
    navigate("/login");
  }

  return (
    <div className="chat">
      <ChatRoom />
    </div>
  );
}

export default Chat;
